:root {
    --base: #07b27b;
}

html {
    scroll-behavior: smooth;

    body {
        overflow-x: hidden;
    }
}

/********** common setting **********/
h2 {
    font-size: 2.8rem !important;
    font-weight: 700;
}

img {
    display: block;
    max-width: 100%;
}

a,
button {
    cursor: pointer;
}

.jumbotron {
    margin-bottom: 0 !important;
}

.atlas-cta {
    border-radius: 22px !important;
    padding: 12px 30px !important;
    font-weight: 700;
    transition: .3s ease-in-out !important;
}

.atlas-cta:hover {
    text-decoration: none;
    transform: translateY(-5px);
}

.atlas-cta-wide {
    width: 100%;
}

.cta-green {
    background: var(--base);
    /*color: ;*/
    font-weight: 600!important;
}

.cta-green:hover {
    color: #ffffff;
    background: var(--base);
}

.cta-blue {
    background: #192440;
    color: #ffffff;
}

.cta-blue:hover {
    color: #ffffff;
    background: #121a2e;
}

.cta-ghost {
    border: 2px solid #192440 !important;
    color: #192440;
}

.cta-ghost:hover {
    color: #ffffff;
    background: #121a2e;
}

#banner {
    background-size: cover;
    margin: 0 0 0 0;
    padding: 3rem;

    background: var(--base);
    background: linear-gradient(0deg, rgba(7,178,123,1) 0%, rgba(1,144,98,1) 100%);
}

#banner.short {
    background: linear-gradient(180deg, rgba(7,178,123,1) 0%, rgb(7, 186, 129) 100%);
}

.login-btn {
    background: #fff;
    padding: 0.4rem 1.2rem;
    border-radius: 0.3rem;
    display: inline-flex;
    font-weight: 600;
    border: 2px solid #fff;
    color: var(--base);
}

.login-btn.outline {
    background: none;
    color: #fff;
    border: 2px solid #fff;
    /*padding: 0.5rem 1.2rem!important;*/
}

/********** banner **********/
#banner header {
    overflow: hidden;

}

#banner header img {
    max-width: 230px;
}

/********** feature (skew background) **********/
.feature img {
    width: 100%;
    max-width: 480px;
}

#feature-first {
 /*   background: linear-gradient(168deg, #ffffff 60%, #22ad7b 0);*/
}

#feature-last {
    background: var(--base);

    background: linear-gradient(0deg, rgba(7,178,123,1) 0%, rgba(1,144,98,1) 100%);
}

/********** price table **********/
#price-table {
    padding-top: 4rem !important;
    padding-bottom: 2rem !important;
}

#price-table__premium {
    background: #192440;
    color: #ffffff;
}

#price-table ul li {
    padding: 5px 0;
}

/********** contact **********/
#contact {
    background-size: cover;
}

#contact ul li {
    padding: 3px 0;
}

/********** copyright **********/
#copyright {
    background: #fff;
    padding: 1rem 3rem;
    color: inherit;

    a {
        color: #333333;
    }
}


/********** RWD **********/
@media (max-width: 575px) {
    #banner h1 {
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 7rem!important;
    }

    #banner:not(.mobile) {
        margin: 0;
        height: 100vh;
        padding: 3rem 1rem;

        .container header + div{
            height: 80vh;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            display: flex;
        }
    }
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #eceeef;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}


main {
    min-height: 90vh;
}